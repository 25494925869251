import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { createPinia } from 'pinia';
import { useAuthStore } from './store/auth';
import './assets/style.css'
import { ref, computed } from "vue"

const pinia = createPinia()

import axios from 'axios'
axios.defaults.baseURL = process.env.VUE_APP_API_URL

const app = createApp(App);
app.use(pinia);
app.use(router);
app.use(store)

const errorMessage = ref('')
const successMessage = ref('')
const isLoading = ref(false)

const authStore = useAuthStore();
const user = computed(() => authStore.user?._id);

const logout = async () => {
  isLoading.value = true
  try {
      await axios.post(`/api/client/${user.value}/logout`);
      authStore.logout();
      router.push('/');
  } catch (err) {
      errorMessage.value = "Erreur lors de la déconnexion.";
      hideErrorMessageAfterDelay()
  } finally {
      isLoading.value = false;
  }
};

const hideSuccessMessageAfterDelay = () => {
  setTimeout(() => {
    successMessage.value = ''; 
  }, 3000);
};

const hideErrorMessageAfterDelay = () => {
  setTimeout(() => {
    errorMessage.value = ''; 
  }, 3000);
};

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      logout();
      router.push('/login'); 
    }
    return Promise.reject(error); 
  }
);
setInterval(() => {
  const token = authStore.token;
  if (token) {
    const { exp } = JSON.parse(atob(token.split('.')[1]));
    if (Date.now() >= exp * 1000) {
      logout();
      router.push({ name: 'Login' });
    }
  }
}, 60000); // Check every minute

app.mount('#app');
