// stores/auth.ts
import { defineStore } from 'pinia';
import piniaPersistedState from 'pinia-plugin-persistedstate';

interface User {
  _id: String;
  lastname: string;
  firstname: string;
  email: string;
  phone: Number;
  filiere: String;
  year: String;
  username: string;
  isSuper?: boolean;
  isActive: boolean;
  isAbonne: boolean;
  subscription: String;
  subscriptionEndDate: String
}

interface AuthState {
  token: string | null;
  user: User | null;
}

export const useAuthStore = defineStore('auth', {
  state: (): AuthState => ({
    token: null,
    user: null,
  }),
  actions: {
    setUser(user: User) {
      this.user = user;
    },
    setToken(token: string) {
      this.token = token;
    },
    logout() {
      this.token = null;
      this.user = null;
      localStorage.removeItem('Azerty234569NHyc'); // Assurez-vous de supprimer la clé persistante du sessionStorage
    },
  },
  persist: {
    // Utilisation de la sessionStorage comme alternative au localStorage
    key: 'Azerty234569NHyc',
    storage: localStorage,
  },
});
